@import url(./css/rich_text.css);
@import url(./css/comments.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.image-services-list,
.image-services-list>img {
  max-width: 30px;
  max-height: 30px;
  object-fit: contain;
}

[class*="col_stikcy_"] {
  display: block !important;
  width: calc(100vw - 290px) !important;
  height: calc(100vh - 200px) !important;
  overflow-x: scroll;
}


[class*="col_stikcy_"]::-webkit-scrollbar {
  -webkit-appearance: none;
}

[class*="col_stikcy_"]::-webkit-scrollbar:vertical {
  width: 8px;
}

[class*="col_stikcy_"]::-webkit-scrollbar-button:increment,
[class*="col_stikcy_"]::-webkit-scrollbar-button {
  display: none;
}

[class*="col_stikcy_"]::-webkit-scrollbar:horizontal {
  height: 8px;
}

[class*="col_stikcy_"]::-webkit-scrollbar-thumb {
  background-color: #303030;
  border-radius: 4px;
  border: 1px solid #FFF;
}

[class*="col_stikcy_"]::-webkit-scrollbar-track {
  border-radius: 4px;
}

[class*="document_verify_"] {
  cursor: pointer;
  position: relative;
  width: 200px;
}

.document_verify_front:hover::before {
  content: "Frontal";
  background: rgba(0, 0, 0, .5);
}

.document_verify_back:hover::before {
  content: "Reverso";
  background: rgba(0, 0, 0, .5);
}

[class*="document_verify_"]::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  font-weight: 700;
}

.col_stikcy_3>*>tr> :nth-child(3) {
  background-color: #303030;
  z-index: 1;
  position: sticky;
  left: 0;
}

.col_stikcy_3>*>tr:hover> :nth-child(3) {
  background-color: rgb(93 93 93);
}

.clickable {
  cursor: pointer;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.ml-1,
.mx-1 {
  margin-left: 10px;
}

.d-block {
  display: block !important;
}

.mr-1,
.mx-1 {
  margin-right: 10px;
}

.export_modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.role_chip {
  overflow: hidden;
  max-width: 95%;
}

.role_chip>span {
  display: inline;
  text-overflow: ellipsis;
  overflow: hidden;
}

.export_paper {
  width: 400px;
  border: 2px solid #000;
  color: #FFF;
  padding: 16px 32px 24px;
  position: absolute;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
  background-color: #424242;
}

.text-center {
  text-align: center;
}

.tabbed-form-custom>div>div>div>div {
  display: flex;
  flex-wrap: wrap;
}

.ra-rich-text-input {
  border: 1px solid #fff !important;
  border-radius: 4px;
}

.ql-container.ql-snow {
  border-top: 1px solid #fff !important;
  height: 300px;
}

.th_1>thead>tr> :nth-child(3)::before {
  text-align-last: justify;
  content: 'Ruta Spread_venta DSC_valor_absoluto Spread_total';
  display: block;
  padding: 0px 14%;
}

.table_route {
  width: 100%;
}

.table_route,
.table_route tr,
.table_route th,
.table_route td {
  border: 1px solid black;
  border-collapse: collapse;
}

.table_route>tbody>tr>td:nth-child(1) {
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.ql-toolbar {
  background-color: #fff !important;
  padding-left: 20px;
}

.table_balance {
  width: 100%;
}

.swal_balance_error,
.swal_balance {
  background-color: #5d5d5d !important;
}

.swal_balance {
  width: 70% !important;
  height: fit-content;
}

.swal_balance h2,
.swal_balance_error h2,
.swal_balance_error div,
.table_balance td,
.table_balance th,
.table_balance>tbody>tr>td:nth-child(1) {
  color: white;
}

.table_balance td a {
  color: rgb(101, 165, 255);
}

.table_balance th,
.table_balance td {
  padding: 7px;
}

.table_balance,
.table_balance tr,
.table_balance th,
.table_balance td {
  border: 1px solid black;
  border-collapse: collapse;
}

.table_balance>tbody>tr>td:nth-child(1) {
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.col_alert_1>tbody>tr> :nth-child(1):has(.locked_by_standard_flow) {
  background-color: #c25454;
}

.swal2-container {
  z-index: 99999 !important;
}

.col_alert_1>tbody>tr> :nth-child(1):has(.locked_by_autopay_flow) {
  background-color: #4887DE;
}

.ra-rich-text-input {
  border: 1px solid #fff !important;
  border-radius: 4px;
}

.th_1 > thead > tr > :nth-child(3)::before {
  text-align-last: justify;
  content: 'Ruta Spread_venta DSC_valor_absoluto Spread_total';
  display: block;
  padding: 0px 14%;
}

.table_route {
  width: 100%;
}

.table_route,
.table_route tr,
.table_route th,
.table_route td {
  border: 1px solid black;
  border-collapse: collapse;
}

.table_route > tbody > tr > td:nth-child(1) {
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.button-import-routes {
  position: absolute;
  z-index: 1;
  text-align: center;
  background-color: rgb(0, 245, 165);
  border: none rgb(255, 255, 255);
  border-radius: 4px;
  padding: 7px;
  cursor: pointer;
  color: white;
  right: 150px;
  top: 97px;
}

.click_able_row>tr:hover {
  background-color: #303030;
  cursor: pointer;
}

[class*="btn"] {
  width: 125px !important;
  margin-bottom: 4px !important;
}

.btn-score {
  background-color: #63666B !important;
  color: #FFFFFF;
}

/* SCORE CRYPTO CURRENCIES */
.score-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.score-title {
  padding-top: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.score-row-buttons {
  display: flex;
  flex-direction: row;
}

[class*="score-risk"] {
  font-size: 18px;
  line-height: 1.8;
  width: 85px;
  color: #FFFFFF;
  padding: 10px;
  margin: 0;
  text-align: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.score-risk-high {
  background-color: #DD5335;
}

.score-risk-medium {
  background-color: #F2994A;
}

.score-risk-low {
  background-color: #14AD92;
}

.score-owner {
  font-size: 18px;
  text-align: center;
  line-height: 0.9;
  margin-left: 25%;
}

.score-owner>span {
  font-size: 12px !important;
  font-weight: 400;
}

.json-table-fortress {
  border-collapse: collapse;
  width: 100%;
}

.json-table-fortress td,
.json-table-fortress th {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.json-table-fortress-key {
  font-weight: bold;
  margin-bottom: 2px;
  margin-top: 2px;
  padding-right: 4px;
  width: 90px;
}

.json-table-fortress-value {
  margin-bottom: 2px;
  margin-top: 2px;
}

.swal_title {
  font-size: 22px;
  color: #ffffff;
}

.subtitle {
  color: #ffffff;
}

.permission_container span {
  white-space: break-spaces;
}

@media screen and (max-width: 768px) {
  .list-page>div>div:has([class*="Filter-form"]) {
    max-width: calc(100vw - 32px);
  }
}

@media (min-width: 769px) {
  .list-page>div>div:has([class*="Filter-form"]) {
    max-width: calc(100vw - 310px);
  }

  main:has([style*="width: 55"]) .list-page>div>div:has([class*="Filter-form"]) {
    max-width: calc(100vw - 125px);
  }
}