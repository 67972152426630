.ra-rich-text-custom {
  position: relative;
  border: 1px solid #888888;
  border-radius: 8px !important;
}

.ra-rich-text-custom .ql-toolbar {
  background-color: #2F2F2F;
  border-radius: 8px !important;
  padding: 10px !important;
  margin-top: 0 !important;
}

.ra-rich-text-custom .ql-toolbar * {
  color: #C1C1C1 !important;
}

.ra-rich-text-custom .ql-toolbar .ql-formats button:not(:hover) *,
.ra-rich-text-custom .ql-toolbar .ql-formats .ql-picker:not(:hover) * {
  stroke: #C1C1C1 !important;
}

.ra-rich-text-custom .ql-toolbar .ql-formats button.ql-active *  {
  stroke: #06C !important;
}

.ra-rich-text-custom .ql-toolbar .ql-picker-item {
  color: #444 !important;
}

.ra-rich-text-custom .ql-container {
  margin-right: 50px;
  margin-left: 15px;
}

.ra-rich-text-custom .ql-container .ql-editor {
  min-height: 80px !important;
  color: #FFF !important;
}

.ra-rich-text-custom > button {
  position: absolute;
  right: 10px;
  top: 65%;
  transform: translate3d(0, -50%, 0);
  background: transparent;
  border: 0;
  cursor: pointer;
}

.ra-rich-text-custom > button:disabled {
  opacity: .2;
}

.ra-rich-text-custom > button > img {
  width: 24px;
  height: 24px;
}

.rich-text-country:hover > div > :nth-child(2) {
  background-color: #FFFFFF;
}