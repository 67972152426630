.comments-container {
  margin-top: 60px;
  max-width: 660px;
  width: 660px;
}

[class*="item-comment_"] {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
}

.item-comment_container {
  border-bottom: 1px solid #888888;
  margin-bottom: 24px;
}

.item-comment_author,
.item-comment_body {
  flex: 0 0 100%;
  overflow: hidden;
}
.item-comment_author p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  margin: 0;
}

.item-comment_body * {
  color: #FFF;
}

.item-comment_body img {
  max-width: 100%;
}